<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo" >
          <h2 class="brand-text text-success ml-0">
            N9VA
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Esqueceu sua senha?
        </b-card-title>
        <b-card-text class="mb-2">
          Digite seu email e nós enviaremos instruções para alterar sua senha
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-if="cmd_enable"
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="EmailCadastrado@gmail.com"
                />
                <b-form-input
                  v-else
                  disabled
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="EmailCadastrado@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="success"
              block
              @click="validationForm"              
            >
              Enviar link
            </b-button>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{path:'/'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      cmd_enable: true,
      userEmail: '',
      required,
      email,
    }
  },
  methods: {    
    async changePassword(){
      var self = this;
      let data = {
        "action": "sendResetPwd",
        "value": { "email": this.userEmail }
      }

      const headers = { 
        "Authorization": this.$store.state.token_api,
      };

      await axios.post('https://tekie.cloud:3031/authmanagement', data, { headers })
        .then(function (response) {
          //"response = ", response);
          self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email enviado!',
            icon: 'XIcon',
            variant: 'success',
            },
          })
          self.cmd_enable = false;
        })
        .catch(function (e) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Algo deu errado',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          //" Erro ao tentar trocar senha: ",e);
        });


    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {          
          this.changePassword();
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>